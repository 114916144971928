<template>
  <div>
    <div class="container">
      <div class="row text-center">
        <div class="col-md-10 mx-auto">
          <h1 class="green-header-2-text mb-4">
            Dr Gayetri joins the Amchara Health team as a lead associate
            Functional Medicine Doctor
          </h1>
          <p>
            Dr Gayetri is here to support you before or after an Amchara Health
            retreat. Using a functional medicine approach and covering a wide
            range of health conditions, Dr Gayetri can work with you in gaining
            a deeper perspective into your personal health and wellbeing needs.
          </p>
          <p></p>
          <h4 class="mb-5">
            “Working in partnership with you to design a personalised program to
            optimise your health and allow you to live life to its fullest
            potential, so that you continue to thrive today, tomorrow and
            always.”
          </h4>
          <img
            width="500"
            src="@/assets/images/teams/dr-gayetri.jpg"
            alt="Dr Gayetri - Functional Medicine Doctor"
            class="img-fluid mb-5"
          />
          <p>
            After suffering with her own health issues, Dr Gayetri turned to
            Functional Medicine as a way to regain her own health. She has now
            gone on to support hundreds of individuals across a whole spectrum
            of health challenges, including, women's health issues (including
            PCOS, sub fertility, menopause, hormonal imbalance), male health
            (prostate issues), digestive health, diabetes, autoimmunity, fatigue
            and more.
          </p>
          <p>
            Dr Gayetri combines her 11 years' experience as a GP with extensive
            training and experience using a Functional Medicine approach with
            her clients.
          </p>
          <p class="mb-5">
            Many people have become frustrated by conventional approaches to
            chronic health issues and one of Dr Gayetri's passions is to help
            those individuals find the answers they deserve through her 1-2-1
            consultations and personalised Functional Medicine Programs.
          </p>
          <h4 class="mb-5" style="color: #8bc34a">
            How can Dr Gayetri support you?
          </h4>
          <p>
            Using a Functional Medicine approach allows Dr Gayetri insight into
            all areas of your life to understand what may be influencing your
            health. Her conventional background also allows for quick assessment
            of red flags that may require referral.
          </p>
          <p>
            In addition to this, Dr Gayatri utilises more expansive functional
            assessments to help uncover the underlying causes of how you feel
            and then combines her experience and knowledge in both conventional
            and functional medicine to create your unique Personalised Health
            program.
          </p>
          <p>
            Dr Gayetri is a member of the Institute for Functional Medicine,
            General Medical Council, British Medical Association, Royal College
            of General Practitioners and is also a member of the Faculty of
            Sexual & Reproductive Healthcare.
          </p>
          <p>
            If you have a burning health question or a frustrating health
            challenge you feel has been holding you back from living your life
            to its fullest, you can take our FREE health questionnaire to
            kickstart your journey to optimum health and getting those answers
            you've been waiting for.
          </p>
        </div>
      </div>

      <div class="row mt-5 text-center">
        <div class="col">
          <a
            href="https://drgayetri.co.uk/free-health-assessment-link/"
            class="btn btn-orange-gradient enquire-now-button"
            >Download My Health Questionnaire</a
          >
        </div>
      </div>
    </div>

    <div class="container my-5">
      <div class="row text-center">
        <div class="col">
          <!--<a href="/terms/print" class="btn btn-success" target="_blank">
            <i class="fa fa-download me-1"></i>Download as PDF
          </a>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).title,
      content: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).description,
      // titleTemplate: null,
    };
  },
};
</script>

<style>
</style>